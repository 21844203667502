import React, { useEffect, useState, useContext } from 'react';
import M from "materialize-css";
import { useHistory, useParams } from "react-router-dom";

import steps from '../../db/medprobs.json';
import steps3 from '../../db/currentmeds.json';
import steps5 from '../../db/system.json';

import { genKey, getItem } from '../../utils/initialstate';
import { Icon, TextInput, Collapsible, CollapsibleItem, Row, Col, Divider, Collection, CollectionItem, CardPanel } from 'react-materialize';
import './medical.css';

import MedicalRecordItem from '../../components/MedicalRecordItem/MedicalRecordItem';
import MedicalRecordsSummary from './medical-records-summary';


import { initialLoginDataState, initialClientsInfoGraphQL, initialClientsProfileInfoGraphQL, userCredentials } from '../../utils/initialstate.js'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import _ from 'lodash';
import AppContext from '../../AppContext';
import { Typography } from '@mui/material';

export default function StepItems() {
  const context = useContext(AppContext);
  const params = useParams();
  const [clientProfileInfo, setClientProfileInfo] = useState(initialClientsProfileInfoGraphQL);
  const [clientPersonalInfo, setClientPersonalInfo] = useState(initialClientsInfoGraphQL);
  const [physicians, setPhysicians] = useState([]);
  const [userLogState, setUserState] = useState(initialLoginDataState);
  const [formValues, setFormValues] = useState({});
  const [chatLogHistory, setChatLogHistory] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [signed, setSigned] = useState(false);

  const history = useHistory();

  const getUserProfileFromGraphQL = async () => {
    let clid = "";
    let csub = "";

    if (userLogState.clientid !== '' && userLogState.clientid !== undefined) {
      clid = userLogState.clientid;
      csub = userLogState.sub;
      let respClients = await API.graphql(
        graphqlOperation(queries.getClients, { id: clid })
      );

      setClientPersonalInfo({ ...clientPersonalInfo, ...respClients.data.getClients });

      let tempDataPhysicians = JSON.parse(respClients.data.getClients.other_physicians);
      setPhysicians(tempDataPhysicians);

      let respClientsProfile = await API.graphql(
        graphqlOperation(queries.queryClientsProfileByClientIdAndSub, { client_id: clid, sub: { eq: csub } })
      );

      console.log("CLIENT PROFILE", respClientsProfile.data.queryClientsProfileByClientIdAndSub.items);

      if (respClientsProfile.data.queryClientsProfileByClientIdAndSub.items.length > 0) {
        setClientProfileInfo({ ...clientProfileInfo, ...respClientsProfile.data.queryClientsProfileByClientIdAndSub.items[0] });
      }
    }
  }

  useEffect(() => {

    const callThis = async () => {
      var elems = document.querySelectorAll('.collapsible');
      var instances = M.Collapsible.init(elems);
      var instance = instances[0];
      instance.open(0);

      let loggedUser = await userCredentials('FROM PROFILE');

      setUserState({ ...loggedUser });

      let chatHistory = getItem(context.clientInfo.id);
      let newChatValue = chatHistory;

      if (!_.isEmpty(chatHistory)) {
        console.log("Check yung naka store na chat history na galing sa recent chat with Hygeia...");
        setChatLogHistory({ ...newChatValue });
      }
    }
    callThis();

  }, []);

  useEffect(async () => {
    getUserProfileFromGraphQL();
  }, [userLogState]);

  useEffect(async () => {

    console.log(clientProfileInfo);

  }, [clientProfileInfo]);



  const handleSelect = (key) => {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, { accordion: true });
    var instance = instances[0];
    instance.open(key);
  }


  const collapse = () => {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, { accordion: false });
    var instance = instances[0];
    for (let c = 0; c < elems[0].children.length; c++) {
      instance.close(c);
    }
  }

  const expand = () => {
    var elems = document.querySelectorAll('.collapsible');
    var instances = M.Collapsible.init(elems, { accordion: false });
    var instance = instances[0];
    for (let c = 0; c < elems[0].children.length; c++) {
      instance.open(c);
    }
  }

  /*Function below is not used. Do not delete, for reference...*/
  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    console.log('VALUE', formValues[e.target.name]);

    if (formValues[e.target.name] === 'on')
      setFormValues({ ...formValues, [e.target.name]: 'off' })
    else if (formValues[e.target.name] === 'off')
      setFormValues({ ...formValues, [e.target.name]: 'on' })
    else
      if (e.target.getAttribute('data-label') === 'on')
        setFormValues({ ...formValues, [e.target.name]: 'on' })
      else
        setFormValues({ ...formValues, [e.target.name]: 'off' })
  }

  const handleChangeDate = (p_name, p_value) => {
    console.log("Change Date", p_name, String(p_value));
    if (typeof p_value != "undefined") {
      setFormValues({ ...formValues, [p_name]: p_value });
      setIsDirty(true);
    }
  }

  const handleChangeCheckBox = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.getAttribute('data-label') });
    setIsDirty(true);
  }

  const handleChangeText = (e) => {
    console.log(e.target.name, e.target.value);
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setIsDirty(true);
  }


  useEffect(() => {
    console.log(formValues);
  }, [formValues]);

  const goBack = () => {

    if (typeof history.location.state !== 'undefined') {
      if (typeof history.location.state.frompath !== 'undefined')
        history.push(history.location.state.frompath, { from: "/profile" });
    }

    if (typeof history.location.state !== 'undefined') {
      if (typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    } else {
      goHome();
    }
  }



  const goHome = () => {

    history.push("/meddetails", { from: "/medrec" });
  }

  const gotoChatRoom = () => {

    let client_id = context.userState.clientid;

    if (params.client_id) {
      client_id = params.client_id;
      const location = {
        pathname: `/chime/${client_id}/${params.id}/${params.meeting_id}`,
        state: { from: '/medical' }
      }
      history.push(location);
    } else {
      const location = {
        pathname: `/client`,
        state: { from: '/medical' }
      }
      history.push(location);
    }
    //history.push("/chime");
  }

  return (
    <React.Fragment>
      <nav>
        <div className="nav-wrapper hygeiabackground">
          <div>

            <a href="#!" className="breadcrumb" style={{ "paddingLeft": "10px" }} onClick={() => { goHome() }}>Home</a>
            <a href="#!" className="breadcrumb" style={{ "paddingLeft": "10px" }} onClick={() => { goBack() }}>Back</a>
            <a href="#!" className="breadcrumb" style={{ "paddingLeft": "10px" }}>Medical Record</a>

          </div>
        </div>
      </nav>
      <Row container>
        <Col s={12} m={8} className='offset-m2'>
          <div style={{ "paddingTop": "10px" }}>
            <h5 className="center-align red-text flow-text">Medical Record</h5>
          </div>
        </Col>
        <Col s={12} m={8} className='offset-m2'>
          <blockquote className="red-text text-darken-3">
            Since this is your medical history and it will be used in evaluating your health,
            it is extremely important that the questions be answered as accurately and completely as possible.
            All information is kept confidential.
          </blockquote>
        </Col>
        <Col s={12} m={8} className='offset-m2' style={{ marginBottom: "10px", marginTop: "10px" }}>


          <Col s={12} m={12}>

            <a className="smallbutton green" style={{ backgroundColor: "#23b241", "margin": "5px" }} onClick={() => { gotoChatRoom() }}>Go to meeting Room</a>
            <div className="hide-on-med-and-up" style={{ height: "15px" }}></div>
            <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={() => { expand() }}>Expand All</a>
            <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { collapse(); }}>Collapse All</a>

          </Col>


        </Col>


        <Col s={12} m={8} className='offset-m2'>

          <Collapsible
            accordion={false}
            popout
            container
            onSelect={(e) => { handleSelect(e) }}
          >

            <CollapsibleItem

              header='Personal Details'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"

            >
              <Row>
                <Col s={12} m={6}>
                  <TextInput
                    id="idName"
                    label="Name"
                    value={`${clientPersonalInfo.last_name},  ${clientPersonalInfo.first_name}`}
                    noLayout={true}
                    readOnly={true}
                  />
                </Col>
                <Col s={12} m={3}>
                  <TextInput
                    id="idDateOfBirth"
                    label="Date of Birth"
                    value={`${clientPersonalInfo.date_of_birth}`}
                    noLayout={true}
                    readOnly={true}
                  />
                </Col>
                <Col s={12} m={3}>
                  <TextInput
                    id="idGender"
                    label="Gender"
                    value={`${clientPersonalInfo.gender}`}
                    noLayout={true}
                    readOnly={true}
                  />
                </Col>
                <Col s={12} m={12}>
                  <Divider></Divider>
                </Col>
                <Col s={12} m={12}>
                  <Collection header={<h6 className="green-text text-darken-4">Provider(s)</h6>}>
                    {
                      physicians.map((item, index) =>
                        item.full_name.length > 0 ?
                          <CollectionItem key={`${genKey()}${index}`}>
                            {item.full_name}
                          </CollectionItem> : null

                      )
                    }



                  </Collection>
                </Col>
                <Col s={12} m={12}>
                  <div style={{ "margin": "15px" }}>
                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={() => { }}>Edit Profile</a>
                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { handleSelect(1); }}>Next</a>
                  </div>
                </Col>

              </Row>
            </CollapsibleItem>

            <CollapsibleItem
              header='Why are you here today?'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"
            >
              <Row container>
                <Col s={12} m={12}>

                  <Typography style={{ margin: "5px" }}>Why are you here today?</Typography>

                </Col>

                <Col s={12} m={12}>

                  <CardPanel>

                    {
                      chatLogHistory.visit_for === 'Me' ?
                        <React.Fragment>
                          <p>
                            {
                              signed === false ?
                                <span>
                                  Your main concern is "{chatLogHistory.main_concern.replace(/_/g, ' ')}". Other detail(s) below identified from our conversation
                                </span> : null
                            }

                          </p>
                          <p>
                            {
                              signed === false ?
                                <span>
                                  Symptoms: {chatLogHistory.main_symptom.replace(/_/g, ' ')}
                                </span> : null
                            }
                          </p>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <p>
                            {
                              signed === false ?
                                <span>
                                  The main concern of your dependent is {chatLogHistory.main_concern}. Other detail(s) below identified from our conversation:
                                </span> : null
                            }
                          </p>
                          <p>
                            {
                              signed === false ?
                                <span>
                                  Symptoms: {chatLogHistory.main_symptom}
                                </span> : null
                            }
                          </p>
                        </React.Fragment>
                    }
                    <p>
                      Please click the next button below to proceed in filling up the rest of the medical record.
                    </p>
                  </CardPanel>

                </Col>

                <Col s={12} m={12}>
                  <div style={{ "margin": "15px" }}>
                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { handleSelect(2); }}>Next</a>
                  </div>
                </Col>
              </Row>


            </CollapsibleItem>



            <CollapsibleItem
              header='Past and Present Medical Problems'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"
            >

              <Row container>
                {
                  steps.map((item1, key1) =>
                    <Row key={`SUB_ITEM_CATEGORY_LEVEL${key1}`}>
                      <Col s={12} m={12}>
                        <h6 className="flow-text green-text text-darken-3">{item1.category}</h6>
                        <span className="flow-text green-text text-darken-3" style={{ "fontSize": "16px" }}>{item1.description || ''}</span>
                      </Col>
                      <Col s={12} m={12}>
                        <Divider />
                      </Col>
                      {

                        item1.child.map((item2, key2) =>
                          <div key={`SUB_CATEGORY_OPTIONS${key2}}`}>
                            <Col s={12} m={4}>
                              <div className="valign-wrapper" style={{ "marginTop": "15px" }}>
                                <i className="tine material-icons green-text text-darken-3 valign-wrapper">label_outline</i>
                                <span style={{ "fontSize": "16px", "marginLeft": "4px" }}>{key2 + 1} - {item2.subcat.category} </span>
                              </div>
                            </Col>
                            <Col s={12} m={7}>
                              <MedicalRecordItem
                                formValues={formValues}
                                items={item2.subcat.options}
                                setFormValues={setFormValues}
                                handleChangeCheckBox={handleChangeCheckBox}
                                handleChangeText={handleChangeText}
                                handleChangeDate={handleChangeDate}
                                category={item1.category}

                              />
                            </Col>
                          </div>
                        )
                      }
                      <Col s={12} m={12}>
                        <Divider />
                      </Col>
                    </Row>
                  )
                }
              </Row>

              <Row container>
                <Col s={12} m={12}>
                  <div style={{ "margin": "15px" }}>
                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { handleSelect(3); }}>Next</a>
                  </div>
                </Col>
              </Row>

            </CollapsibleItem>

            <CollapsibleItem
              header='Current Medications and Allergies'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"
            >
              <Row container>
                {
                  steps3.map((item1, key1) =>
                    <Row key={`SUB_ITEM_CATEGORY_LEVEL1_${key1}`}>
                      <Col s={12} m={12}>
                        <h6 className="flow-text green-text text-darken-3">{item1.category}</h6>
                        <span className="flow-text green-text text-darken-3" style={{ "fontSize": "16px" }}>{item1.description || ''}</span>
                      </Col>
                      <Col s={12} m={12}>
                        <Divider />
                      </Col>
                      {
                        item1.child.map((item2, key2) =>
                          <div key={`SUB_CATEGORY_OPTIONS1_${key2}}`}>
                            <Col s={12} m={4}>
                              <div className="valign-wrapper" style={{ "marginTop": "15px" }}>
                                <i className="tine material-icons green-text text-darken-3 valign-wrapper">label_outline</i>
                                <span style={{ "fontSize": "16px", "marginLeft": "4px" }}>{key2 + 1} - {item2.subcat.category} </span>
                              </div>
                            </Col>
                            <Col s={12} m={7}>
                              <MedicalRecordItem
                                formValues={formValues}
                                items={item2.subcat.options}
                                setFormValues={setFormValues}
                                handleChangeCheckBox={handleChangeCheckBox}
                                handleChangeText={handleChangeText}
                                category={item1.category}

                              />
                            </Col>
                          </div>
                        )
                      }
                      <Col s={12} m={12}>
                        <Divider />
                      </Col>
                    </Row>
                  )
                }
              </Row>

              <Row>
                <Col s={12} m={12}>
                  <div style={{ "margin": "15px" }}>
                    <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { handleSelect(4); }}>Next</a>
                  </div>
                </Col>
              </Row>
            </CollapsibleItem>

            <CollapsibleItem
              header='Review of Systems'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"
            >
              {
                signed === true ?
                  <Row container key={genKey()}>
                  </Row>
                  :
                  <React.Fragment key={genKey()}>
                    <Row container>
                      {
                        steps5.map((item1, key1) =>
                          <React.Fragment key={genKey()}>
                            {
                              item1.child.map((item2, key2) =>
                                <React.Fragment key={genKey()}>
                                  {

                                    item1.category === chatLogHistory.classification && item2.subcat.category === chatLogHistory.main_symptom.replace(/_/g, ' ') ?

                                      <Row key={`SUB_ITEM_CATEGORY_LEVEL1_${key1}`}>
                                        <Col s={12} m={12}>
                                          <h6 className="flow-text green-text text-darken-3">{item1.category}</h6>
                                          <span className="flow-text green-text text-darken-3" style={{ "fontSize": "16px" }}>{item1.description || ''}</span>
                                        </Col>
                                        <Col s={12} m={12}>
                                          <Divider />
                                        </Col>


                                        <div key={`SUB_CATEGORY_OPTIONS1_${key2}}`}>
                                          <Col s={12} m={4}>
                                            <div className="valign-wrapper" style={{ "marginTop": "15px" }}>
                                              <i className="tine material-icons green-text text-darken-3 valign-wrapper">label_outline</i>
                                              <span style={{ "fontSize": "16px", "marginLeft": "4px" }}>{item2.subcat.category} </span>
                                            </div>
                                          </Col>
                                          <Col s={12} m={7}>

                                            <MedicalRecordItem
                                              formValues={formValues}
                                              items={item2.subcat.options}
                                              setFormValues={setFormValues}
                                              handleChangeCheckBox={handleChangeCheckBox}
                                              handleChangeText={handleChangeText}
                                              category={item1.category}
                                              checkedItem={true}
                                              readOnly={true}
                                            />

                                          </Col>
                                        </div>


                                        <Col s={12} m={12}>
                                          <Divider />
                                        </Col>
                                      </Row>


                                      : null
                                  }
                                </React.Fragment>


                              )
                            }
                          </React.Fragment>

                        )
                      }
                    </Row>
                    <Row container>

                      <Col s={12} m={12}>
                        <div style={{ "margin": "15px" }}>
                          <a href="#!" className="smallbutton green" style={{ "minWidth": "60px", "maxWidth": "60px", "margin": "5px" }} onClick={(e) => { handleSelect(5); }}>Next</a>
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
              }

            </CollapsibleItem>

            <CollapsibleItem
              header='Medical History Summary'
              icon={<Icon className='green-text text-darken-3'>local_hospital</Icon>}
              node="div"
            >

              <MedicalRecordsSummary
                formValues={formValues}
                setFormValues={setFormValues}
                clientPersonalInfo={clientPersonalInfo}
                physicians={physicians}
                setChatLogHistory={setChatLogHistory}
                chatLogHistory={chatLogHistory}
                isDirty={isDirty}
                setIsDirty={setIsDirty}
                signed={signed}
                setSigned={setSigned}
              />



            </CollapsibleItem>
          </Collapsible>

        </Col>
      </Row>



    </React.Fragment>
  );
}