import React, { useEffect, useState, useContext } from 'react'
import { Preloader } from 'react-materialize';
import hygeia from '../../assets/imgs/logo_64.png';
import info1 from '../../db/medprobs.json';
import info2 from '../../db/currentmeds.json';
import info3 from '../../db/system.json';
import fonttLocation from '../../assets/Hurricane-Regular.ttf';
import 'materialize-css';
import { genKey, getItem } from '../../utils/initialstate';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import './medical.css'
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { getAge } from '../../utils/tools';
import { useParams } from "react-router-dom"
import _ from 'lodash'
import { useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import html2pdf from 'html2pdf.js';
import '../../fonts/Hurricane-Regular.ttf';
import AppContext from '../../AppContext'
import { Storage } from 'aws-amplify';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfdoc from '../../assets/imgs/md.pdf';
import { Modal } from 'react-materialize';
import ShowLoader from '../../components/showloader/showloader';
import ViewMedical from './viewmedical';
import autoTable from 'jspdf-autotable';
import { ReturnFont } from './Hurricane-Regular-normal';


//import PDF, { Text, AddPage, Line, Image, Table, Html, jsPDF } from 'jspdf-react'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const MedicalRecordsSummary = (props) => {
  /*Get the Medical Records data from the props*/
  const params = useParams();
  const history = useHistory();
  const context = useContext(AppContext);
  
  const [loader, setLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("Please wait while I am processing your request...");

  const { formValues, setFormValues, clientPersonalInfo } = props;
  const [medicalRecord, setMedicalRecord] = useState({});
  const [chatLogHistory, setChatLogHistory] = useState({});
  const [recordId, setRecordId] = useState('');
  const [historyRecord, setHistoryRecord] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [successUploadKey, setSuccessUploadKey] = useState('');
  const [numPages, setNumPages] = useState(3);
  
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [physiciansList, setPhysiciansList] = useState([]);
  const [medicalRequests, setMedicalRequests] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [pdf, setPdf] = useState();
  const [shortValueReviewOfSystems, setShortValueReviewOfSystems] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [enableSign, setEnableSign] = useState(false);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase();
  }

  const titleCase = (str) => {
    return str.replace(/[^\ \/\-\_]+/g, capitalize);
  }

  const fetchList = async () => {
    console.log("FETCH LIST TRIGGERED: ", params.medical_record_id)
    if (!_.isEmpty(context.clientInfo)) {
      if (context.clientInfo.id && !recordId) {


        let response_medical_records = await API.graphql(
          graphqlOperation(queries.queryMedicalRecordsByClientId, { client_id: context.clientInfo.id })
        );

        let items = response_medical_records.data.queryMedicalRecordsByClientId.items;

        let maxRevision = items.reduce((max, item) => Number(max.revision) > Number(item.revision) ? max : item);
        console.log("Max Revision", maxRevision);
        setMedicalRecord(maxRevision);

        let data = JSON.parse(maxRevision.data);

        setFormValues({ ...data });
        //setRows(response_medical_records.data.queryMedicalRecordsByClientId.items);
      }

      // if(context.clientInfo.id && recordId){
      //   if(params.medical_record_id.toLowerCase() !== 'load'){
  
      //     const res = await API.graphql(
      //       graphqlOperation(queries.getMedicalHistory,  { id: recordId })
      //     );
      //     console.log("MEDICAL RECORD BASED ON ID", res.data.getMedicalHistory);
      //     setMedicalRecord(res.data.getMedicalHistory);

      //     let data = JSON.parse(res.data.getMedicalHistory.data);
      //     setFormValues({...data});
      //   }
      // }

    }
  }

  const fetchListLimitedToProvider = async () => {

    if (!_.isEmpty(context.clientInfo)) {
      if (context.clientInfo.id && params.client_id) {


        let response_medical_records = await API.graphql(
          graphqlOperation(queries.queryMedicalRecordsByClientId, { client_id: params.client_id })
        );

        let items = response_medical_records.data.queryMedicalRecordsByClientId.items;

        let maxRevision = items.reduce((max, item) => Number(max.revision || 0) > Number(item.revision || 0) ? max : item);

        setMedicalRecord(maxRevision);

        let data = JSON.parse(maxRevision.data);

        setFormValues({ ...data });
        //setRows(response_medical_records.data.queryMedicalRecordsByClientId.items);
      }

    }
  }

  useEffect(() => {
    if (params.medical_record_id.toLowerCase() !== 'load' && !params.id) {
      setRecordId(params.medical_record_id);
    }
  }, []);

  useEffect(async () => {
    if (params.medical_record_id.toLowerCase() === 'load') {
      //fetchList();
    }

    if(params.medical_rec_id && params.id && params.client_id){
      //:client_id/:medical_rec_id/:id
      /*
        NOTE: If all 3 parameters exist then the request came from the provider window
      */
      fetchListLimitedToProvider();
    }

    

    // let medical_requests = await API.graphql(
    //   graphqlOperation(queries.queryMedicalShareHistoryByClientId,  { client_id: context.clientInfo.id })
    // );

    // let items = [];
    // for(const item of medical_requests.data.queryMedicalShareHistoryByClientId.items){
    //   items.push({
    //     client_id : item.client_id
    //     , provider_id: item.provider_id
    //     , session_id: item.session_id
    //     , provider_email_address: item.provider_email_address
    //     , provider_name: item.provider_name
    //     , phone_number: item.provider_phone_number
    //     , npi_id: item.npi_id
    //     , medical_record_id: item.medical_record_id
    //     , start_date: item.start_date
    //     , end_date: item.end_date
    //     , granted: item.granted || false
    //     , version: item._version
    //   })
    // }
    
    // setMedicalRequests(items);

    // let chatHistory = getItem(context.clientInfo.id);
   

    // if(!_.isEmpty(chatHistory)){
    //   console.log("Check yung naka store na chat history na galing sa recent chat with Hygeia...");
    //   setChatLogHistory({...chatLogHistory, ...chatHistory});
    // }else{
    //   checkChatLogHistoryFromSession();
    // }

  }, [context.clientInfo])

  useEffect(()=>{

    if(formValues){
      console.log("FORM VALUES ON LOAD", formValues);
    }

  },[formValues])

  useEffect(()=>{

    setIsDirty(props.isDirty);

  },[props.isDirty])

  const checkChatLogHistoryFromSession = async () =>{
    

    console.log("Pumunta dito para e-check yung naka store na chat log history na naka store sa DynamoDB...");
    if(!medicalRecord.session_id) return;

    const id = getItem(context.clientInfo.id + 'session');

    console.log("STORED SESSION ID", id);
    console.log("MEDICAL ID", medicalRecord.session_id);
    console.log("CHATLOG HISTORY", chatLogHistory);

    if(medicalRecord.session_id){

      let session = await API.graphql(
        graphqlOperation(queries.getSessionQueues,  { id: medicalRecord.session_id })
      );
      console.log("SESSION", session);

      if(session.data.getSessionQueues && _.isEmpty(chatLogHistory)){

        if(!_.isEmpty(session.data.getSessionQueues.chat_history) && session.data.getSessionQueues.chat_history !=='null'){
          let parsed = JSON.parse(session.data.getSessionQueues.chat_history);
          console.log("CHAT HISTORY FROM DYNAMODB", parsed);
          setChatLogHistory(parsed);
          props.setChatLogHistory({...props.chatLogHistory, ...parsed});
        }
        

      }
  
      console.log("SESSION QUEUES", session.data.getSessionQueues);
    }
  }


  const getData = async (p_record_id) => {
    // let response_medical_records = await API.graphql(
    //   graphqlOperation(queries.getMedicalHistory, { id: recordId })
    // );
    // //response_medical_records.data.getMedicalHistory.items

    // let foundRecord = JSON.parse(response_medical_records.data.getMedicalHistory.data);

    // setHistoryRecord(response_medical_records.data.getMedicalHistory.data);
    // console.log("DATA CAPTURED", response_medical_records.data.getMedicalHistory);
    // setMedicalRecord({ ...response_medical_records.data.getMedicalHistory });

    // if (foundRecord) {
    //   setFormValues({ ...foundRecord });
    // }

    if(p_record_id){
        if(p_record_id !== 'load'){
  
          const res = await API.graphql(
            graphqlOperation(queries.getMedicalHistory,  { id: p_record_id })
          );
          console.log("MEDICAL RECORD BASED ON ID", res.data.getMedicalHistory);
          setMedicalRecord(res.data.getMedicalHistory);
          
          let data = JSON.parse(res.data.getMedicalHistory.data);
          if(res.data.getMedicalHistory.signed === 'signed'){
            props.setSigned(true);
          }
          setFormValues({...data});
        }
    }
  }

  useEffect(async () => {

    if (recordId === 'create') {

    } else {
      console.log('RECORD ID', recordId);
      if (recordId) {
        if (recordId.length > 0) {
          await getData(recordId)
        }
      }

    }

  }, [recordId])


  const saveMedicalRecord = async (e, s = null) => {
    try{

      if(s === 'signed' && medicalRecord.signed ==='signed' && historyRecord === JSON.stringify(formValues)){
        alert("Signed Medical Record cannot be re-signed without any changes.");
        return;
      }



      setLoaderMessage("Processing your request...(updating DB)");
      setLoader(true);

      let date = new Date();

      const gen_id = uuidv4();
  
      let postData;
  
      let session_id = medicalRecord.session_id ? formValues.session_id : '';
  
      let revision = medicalRecord.id ? parseInt(medicalRecord.revision || 0) + 1 : 1;
  
      let provider = medicalRecord.provider_id ? medicalRecord.provider_id : '';
  
  
      if (!medicalRecord.id) {
        postData = {
          id: gen_id,
          client_id: clientPersonalInfo.id,
          provider_id: provider,
          session_id: session_id,
          revision: revision,
          data: JSON.stringify(formValues),
          created_date: date,
          created_by: formValues.client_id,
          last_updated_date: date,
          last_updated_by: formValues.client_id
        }
  
        let response_data_save = await API.graphql(
          graphqlOperation(mutations.createMedicalHistory, { input: { ...postData } })
        );
  
        if (response_data_save.data.createMedicalHistory) {
          setHistoryRecord(JSON.stringify(formValues));
        }
  
        setMedicalRecord(response_data_save.data.createMedicalHistory);
        setLoaderMessage("Successully committed changes in your Medical History record.");
        
  
      } else {
  
       

        if (s === 'signed' && medicalRecord.signed !=='signed') {
          postData = {
            id: gen_id,
            client_id: medicalRecord.client_id,
            provider_id: medicalRecord.provider_id,
            session_id: medicalRecord.session_id,
            revision: revision,
            data: JSON.stringify(formValues),
            created_date: date,
            signed: 'signed',
            date_signed: new Date().toISOString(),
            created_by: formValues.client_id,
            last_updated_date: date,
            last_updated_by: formValues.client_id
          }
  
          let response_data_save = await API.graphql(
            graphqlOperation(mutations.createMedicalHistory, { input: { ...postData } })
          );
  
      
          if (response_data_save.data.createMedicalHistory) {
            setHistoryRecord(JSON.stringify(formValues));
          }
  
          setMedicalRecord(response_data_save.data.createMedicalHistory);
          
          //Update Medical History Summary
  
          let el = document.getElementById('main');
          //margin: [25, 15, 25, 15],
  
          let opt = {
            margin: [25, 15, 25, 15],
            filename: gen_id + '.pdf',
            image: { type: 'jpeg', quality: 0.58 },
            html2canvas: { scale: 2, logging: true, dpi: 100, letterRendering: true, backgroundColor: '#ffffff' },
            jsPDF: { unit: 'mm', format: 'letter', orientation: 'p' },
            pageBreak: { mode: 'css' }
            
          };

         

          let content = await generatePDF('S3', gen_id);
          

          console.log("CONTENT", content);

        }
   
        if (historyRecord !== JSON.stringify(formValues) && !s) {
          postData = {
            id: medicalRecord.id,
            client_id: medicalRecord.client_id,
            provider_id: medicalRecord.provider_id,
            session_id: medicalRecord.session_id,
            revision: medicalRecord.revision,
            data: JSON.stringify(formValues),
            last_updated_date: date,
            last_updated_by: formValues.client_id,
            signed: medicalRecord.signed === 'signed' ? 'unsigned' : 'no sign',
            _version: medicalRecord._version
          }
          let response_data_save = await API.graphql(
            graphqlOperation(mutations.updateMedicalHistory, { input: { ...postData } })
          );
  
  
          if (response_data_save.data.updateMedicalHistory) {
            setMedicalRecord(response_data_save.data.updateMedicalHistory);
            setHistoryRecord(JSON.stringify(formValues));
          }
          setLoaderMessage("Done updating your medical record.");
          setLoader(false);
          setEnableSign(true);
          props.setIsDirty(false);
        }else{
          setLoader(false);
        }
  
      }
  
    }catch(error){
      console.log(error);
      alert("Encountered an error while converting your Medical Record to PDF." + JSON.stringify(error));
    }
  }

  const signMedicalRecord = (e) => {
    saveMedicalRecord(e, 'signed');
  }

  
  const downloadFile = async () => {
    setLoader(true);
    let filename = 'medical/' + medicalRecord.id + '.pdf' ;

    const result = await Storage.get( filename , { download: true, level: 'public', contentType: "applicaion/pdf" });
  
    setPdf(result.Body);
  }

  useEffect(()=>{
    checkReviewOfSystems();
  },[chatLogHistory])

  useEffect(()=>{
    if(pdf){
      setLoader(false);
      setShowPdf(true);
    }
  },[pdf]);

  useEffect(()=>{
    if(successUploadKey){
      downloadFile();
    }
  },[successUploadKey]);


  useEffect(async()=>{
    
    
    if(medicalRecord){
      console.log("MEIDCAL RECORD DETAILS", medicalRecord);
    }
    
    checkChatLogHistoryFromSession();
    


  },[medicalRecord])

  const shareMedicalRecord = async (e) => {
    setOpenModal(true);
  }

  
  const goBack = () => {

    if (typeof history.location.state !== 'undefined') {
      if (typeof history.location.state.frompath !== 'undefined')
        history.push(history.location.state.frompath, { from: "/profile" });
    }

    if (typeof history.location.state !== 'undefined') {
      if (typeof history.location.state.from !== 'undefined')
        history.push(history.location.state.from, { from: "/user" });
      else
        goHome();
    } else {
      goHome();
    }
  }

  useEffect(()=>{
    const items = [];
    if(props.physicians){
      if(props.physicians.length > 0){
        for(const item of props.physicians){
          items.push({
            npi_id: item.npi_id,
            full_name: item.full_name,
            email: item.email,
            taxonomy: item.taxonomy,
            phone_no: item.phone_no,
            shared: item.shared || false
          })
        }
        setPhysiciansList(items);
      }
    }

  },[props.physicians]);

  const goHome = () => {
    history.push("/", { from: "/profile" });
  }


  const toggleCheckBoxChange = (e, i) => {
      let tmpItems = JSON.parse(JSON.stringify(physiciansList));
      let items = [];
      
      
      for(const item of tmpItems){
        if(i.npi_id === item.npi_id){
          if(e.target.value === 'on' && item.shared === false) items.push({...item, shared: e.target.value === 'on' ? true : false});
          if(e.target.value === 'on' && item.shared === true) items.push({...item, shared: e.target.value === 'on' ? false : true});
        }else{
          items.push(item);
        }
      }
     
      setPhysiciansList(items);
      
  } 

  const toggleCheckBoxChangeOtherProviders = (e, i) => {
    console.log("ITEM", i);

    let tmpItems = JSON.parse(JSON.stringify(medicalRequests));
    let items = [];
    
    
    for(const item of tmpItems){
      if(i.npi_id === item.npi_id){
        if(e.target.value === 'on' && item.granted === "false") items.push({...item, granted: e.target.value === 'on' ? "true" : "false"});
        if(e.target.value === 'on' && item.granted === "true") items.push({...item, granted: e.target.value === 'on' ? "false" : "true"});
      }else{
        items.push(item);
      }
    }
   
    setMedicalRequests(items);
    
  }

  const shareToProviders = async(e) => {
    
    setOpenModal(false);

    setLoader(true);

    let notifyProviders = [];
    let notifiedProviders = [];
    
    console.log("MEDICAL RECORD", medicalRecord);
    
    for(const physician of physiciansList){
      
      if(physician.shared === true && physician.npi_id){
        let found_item = _.find( medicalRequests, function(o) { return o.npi_id === physician.npi_id; });      
        if(!found_item){
          
          let res = await API.graphql(
            graphqlOperation(queries.queryProvidersInfoByNpi, {npi_id:physician.npi_id})
          );
          
          if(res.data.queryProvidersInfoByNpi.items.length > 0){
            let queryString= {
              client: context.clientInfo.first_name + ' ' + context.clientInfo.last_name,
              medical_record_id: medicalRecord.id,
              npi_id: physician.npi_id,
              provider: physician.full_name,
              provider_id: res.data.queryProvidersInfoByNpi.items[0].client_id,
              provider_name: res.data.queryProvidersInfoByNpi.items[0].client_info.first_name + ' ' + res.data.queryProvidersInfoByNpi.items[0].client_info.last_name,
              phone_number: physician.phone_no,
              email_address: physician.email,
              date_shared: (new Date()).toISOString(),
              session_id: medicalRecord.session_id,
              created_date: (new Date()).toISOString()
            }
            notifyProviders.push(queryString);
          }

        }
      }
    }
 
    for(const request of medicalRequests){
      let queryString= {
        client: context.clientInfo.first_name + ' ' + context.clientInfo.last_name,
        medical_record_id: medicalRecord.id,
        npi_id: request.npi_id,
        provider: request.provider_name,
        provider_id: request.provider_id,
        phone_number: request.phone_number,
        email_address: request.provider_email_address,
        version: request.version,
        date_shared: (new Date()).toISOString(),
        session_id: medicalRecord.session_id
      }
      notifyProviders.push(queryString);
    }
 
    let tmpStartDate = new Date();
    let tmpEndDate = new Date();
    tmpEndDate.setDate(tmpStartDate.getDate() + 2);
      
    for(const item of notifyProviders){
      let res;
      
      if(item.revision){
        let putItem = {
          id: item.medical_record_id,
          session_id: item. session_id,
          start_date: tmpStartDate,
          end_date: tmpEndDate,
          grandted: true,
          _revision: item.revision,
        }

        res = await API.graphql(
          graphqlOperation(mutations.updateMedicalShareHistory, {input:{...putItem}})
        );
      }else{
        let tmp_id = uuidv4();

        let postItem = {
          id: tmp_id,
          client_id: context.clientInfo.id,
          created_by: context.clientInfo.id,
          start_date: tmpStartDate,
          end_date: tmpEndDate,
          granted: true,
          last_updated_by: context.clientInfo.id,
          last_updated_date: tmpStartDate,
          medical_record_id: item.medical_record_id,
          npi_id: item.npi_id,
          provider_email_address: item.email_address,
          provider_id: item.provider_id,
          provider_name: item.provider,
          provider_phone_number: item.phone_number,
          revision: medicalRecord.revision,
          created_date: tmpStartDate,
          session_id: item.session_id
        }
        res = await API.graphql(
          graphqlOperation(mutations.createMedicalShareHistory, {input:{...postItem}})
        );
      }
      

      let link = '/internal/provider/medicalrecord/notify'
      API.get('HygeiaInternalAPIs',link,{
        queryStringParameters:{
            ...item
        }
      
      }).then(async(results)=>{
        notifiedProviders.push(item);
        console.log(results);
      }).catch(error => { 
        setLoader(false); 
        console.log("Error",error);
      });   

    }
    setLoader(false);
    props.setIsDirty(false);

    console.log("NOTIFY PROVIDERS", notifyProviders);
    
     
    
  }

  async function generatePDF(p_option = 'NA', p_gen_id = 'NA') {
    window.html2canvas = html2canvas;
  
    function toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }

    let logo;
    
    let parseCell = function(data) {
      let index = -1;
      if(index !== data.row.index){
        if(data.row.index === 0){
          for(let c = 0; c < data.row.raw.length; c++){
            data.row.cells[c].styles.fillColor = [212, 236, 217];
            data.row.cells[c].styles.fontStyle = "bold";
          }
        }else{
          let styleProp = data.row.raw[0]._element.className;
          if(styleProp.toString().indexOf("customHeader") > -1){
            for(let c = 0; c < data.row.raw.length; c++){
              data.row.cells[c].styles.fillColor = [212, 236, 217];
              data.row.cells[c].styles.fontStyle = "bold";
            }
          }
          if(styleProp.toString().indexOf("signature") > -1){
              data.row.cells[0].styles.fontStyle = "bold";
              data.row.cells[0].styles.font = "Hurricane-Regular";
              data.row.cells[0].styles.fontSize = "32";
              
          }

        }
        index = data.row.index;
      }
      
    };

    toDataURL(hygeia, function(dataUrl) {
      logo = dataUrl;

      const doc = new jsPDF()
      let font = ReturnFont();
      doc.addFileToVFS(fonttLocation, font);
      doc.addFont(fonttLocation, 'Hurricane-Regular', 'normal');

      autoTable(doc, { 
        html: '#summaryTable1'
        , margin: {top: 25}
        , tableLineColor: [212, 236, 217]
        , tableLineWidth: 0.75
        , bodyStyles: {
          lineWidth: 0.2,
          lineColor: [236, 243, 238]
        }
        , theme: "plain"
        , columnStyles: {
          0: { cellWidth: 60 }
        },
        didParseCell: parseCell
      }
     
      );

      autoTable(doc, { 
        html: '#summaryTable2'
        , margin: {top: 25}
        , tableLineColor: [212, 236, 217]
        , tableLineWidth: 0.75
        , bodyStyles: {
          lineWidth: 0.2,
          lineColor: [236, 243, 238]
        }
        , theme: "plain"
        ,columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 10 },
          2: { cellWidth: 10 },
        }
        ,didParseCell: parseCell
      }
      
      );

      autoTable(doc, { 
        html: '#summaryTable3'
        , margin: {top: 25}
        , tableLineColor: [212, 236, 217]
        , tableLineWidth: 0.75
        , bodyStyles: {
          lineWidth: 0.2,
          lineColor: [236, 243, 238]
        }
        , theme: "plain"
        ,columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 10 },
          2: { cellWidth: 10 },
        }
        ,didParseCell: parseCell
      }
      
      );
      autoTable(doc, { 
        html: '#summaryTable4'
        , margin: {top: 25}
        , tableLineColor: [212, 236, 217]
        , tableLineWidth: 0.75
        , bodyStyles: {
          lineWidth: 0.2,
          lineColor: [236, 243, 238]
        }
        , theme: "plain"
        ,columnStyles: {
          0: { cellWidth: 80 },
          1: { cellWidth: 10 },
          2: { cellWidth: 10 },
        },
        didParseCell: parseCell
      }

      );
      
      autoTable(doc, { 
        html: '#tab_signature'
        , margin: {top: 25}
        , tableLineColor: [212, 236, 217]
        , tableLineWidth: 0.75
        , bodyStyles: {
          lineWidth: 0.2,
          lineColor: [236, 243, 238]
        }
        , theme: "plain"
       
        ,didParseCell: parseCell
      }

      );
      //
      doc.getFontList(); 
     
      for(let c = 1; c <= doc.getNumberOfPages(); c++){
        doc.setPage(c);
        doc.addImage(logo, "PNG", 17, 10, 50, 10);
        
      
        doc.setFontSize(14);
        doc.setTextColor(34,139,34);
        doc.text('Medical Record', (doc.internal.pageSize.getWidth()/2)-15, 17);
        doc.text((new Date()).toLocaleDateString(), doc.internal.pageSize.getWidth()-39, 17);    

        //set footer
        doc.setFontSize(8);
        doc.setTextColor(150);
        doc.text('Medical Record ( ' + c + '/' + doc.getNumberOfPages() + ' )', doc.internal.pageSize.getWidth() - 33, doc.internal.pageSize.getHeight()-10);
        doc.text((new Date()).toString(), 10, doc.internal.pageSize.getHeight()-10);
      }
     
      let outPut = doc.output('blob');
      
      if(p_option==='S3'){
        setLoaderMessage("Processing your request...(uploading to S3)");
        Storage.put('medical/' + p_gen_id + '.pdf', outPut, {
        resumable: true,
        completeCallback: (event) => {
            setLoaderMessage("Done uploading your medical record. The file can now be shared with your provider");
            setLoader(false);
            console.log("KEY", event.key);

            setPdf(outPut);
            setSuccessUploadKey(event.key);
        },
        progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
        errorCallback: (err) => {
            setLoader(false);
            console.error('Unexpected error while uploading', err);
        }
        });

      
        setLoaderMessage("Done updating your medical record.");
        setLoader(false);
      }
      
      setPdf(outPut);
      props.setSigned(true);
      //return outPut;
    })

  }

  const checkReviewOfSystems = async () =>{
      if(info3 && chatLogHistory){
        console.log('WENT HERE FOR CHECKING...');

        let contentReviewOfSystems = [];

        for(const [index, item] of info3.entries()){
          
         
          for(const [index1, item1] of item.child.entries()){


              for(const [index2, item2] of item1.subcat.options.entries()){
                if(item.category === chatLogHistory.classification && item2.sub_category === chatLogHistory.main_symptom.replace(/_/g, ' ')){

                  contentReviewOfSystems.push(
                    <React.Fragment>
                    <tr key={genKey()} className='customTr'>
                      <td className="customTd" colSpan={5}>&nbsp; </td>
                    </tr>

                    <tr key={genKey()} className='customTr'>
                      <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                      <td style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</td>
                      <td style={{ "width": "50px" }} className="center-align customTd customHeader">No</td>
                      <td className="customTd customHeader" colSpan={2}>Remarks</td>
                    </tr>
                    </React.Fragment>
                  )

                  if(item2.type === 'checkbox'  ){
                      contentReviewOfSystems.push(
                        <React.Fragment key={genKey()}>
                          <tr>
                            <td className="customTd" key={genKey()}><div>{item2.sub_category}</div></td>
                            <td className="center-align customTd"><div>X</div></td>
                            <td className="center-align customTd"></td>
                            <td className="center-align customTd" colSpan={2}></td>
                          </tr>
                        </React.Fragment>
                      )                    
                  }
                  

                }
              }

          }
        }

        setShortValueReviewOfSystems(contentReviewOfSystems);
        // info3.map((item, index) =>{
        //   let addItem = false;
          
        //   item.map((item1, index1)=>{
        //     item1.subcat.options.map((item2, index2) =>{
        //         if(item.category === chatLogHistory.classification && item2.sub_category === chatLogHistory.main_symptom.replace(/_/g, ' ')){
        //             console.log("ITEMS under REVIEW OF SYSTEMS", item, item1, item2);
        //         }
        //     })
        //   })
   
        //   //item.category === chatLogHistory.classification && item2.sub_category === chatLogHistory.main_symptom.replace(/_/g, ' ') ? 'X' : ''
          
   
          
   
        //  })
      }         
      
    
  }
  

  return (
    <div style={{backgroundColor:"white"}}>
      
      <ShowLoader id="medicalHistoryLoader" show={loader} message={loaderMessage}/>

      <ViewMedical show={showPdf} pdf={pdf} id="PDF_SHOW_ID_SUMMARY" setShow={setShowPdf} modal={true}/>

      <div className="papercontainer">

        {
          openPdfDialog === true ?
          <div className="modalHygeiaBackground" >
            <div className="modalHygeiaDialog">
              
                  <Document file={pdfdoc} scale={1} >
                  {
                    Array.apply(null, Array(numPages))
                    .map((x, i)=>i+1)
                    .map(page => 

                    
                        <Page key={genKey()} pageNumber={page} size="A4" style={{ textAlign: 'center' }} canvasBackground={'rgb(240, 240, 240)'} />
                    

                    )
                  }
                  </Document>
                
            </div>
          </div>
          :null
        }
        
        

      

        {loader === true ? <Preloader active={loader} visible={loader} color="red" size="small" /> : null}
       
        <div className="paperdiv" id="main" style={{"width":"570px", "margin":"20px"}}>

          <table className="GeneratedTable" id="summaryTable1">
            <tr className='customTr'>
              <td className="customTd">Name</td>
              <td className="customTd">Gender</td>
              <td className="customTd">Date of birth</td>
              <td className="customTd">Age</td>
            </tr>

            <tr className='customTr'>
              <td className="customTd">{`${clientPersonalInfo.last_name},  ${clientPersonalInfo.first_name}`}</td>
              <td className="customTd">{`${clientPersonalInfo.gender}`}</td>
              <td className="customTd">{`${clientPersonalInfo.date_of_birth}`}</td>
              <td className="customTd">{getAge(clientPersonalInfo.date_of_birth)}</td>
            </tr>
            <tr className="trspacer"></tr>
            <tr className='customTr'>
              <td colSpan={1} style={{ "width": "250px" }}>Why are you here today? { props.signed}</td>
              <td colSpan={4} className="customTd"> 
             
                    {
                      props.signed === false && props.chatLogHistory.main_concern &&  props.chatLogHistory.main_symptom ? 
                        <React.Fragment key={genKey()}>
                        <p>Main Concern: {props.chatLogHistory.main_concern.replace(/_/g, ' ')}</p>
                        <p>Symptom: {props.chatLogHistory.main_symptom.replace(/_/g, ' ')}</p>
                        </React.Fragment>
                      : null
                    }
                    {/* <p>
                      {chatLogHistory.main_concern.replace(/_/g, ' ')}
                    </p> 
                    <p>
                      {chatLogHistory.main_symptom.replace(/_/g, ' ')}
                    </p>  */}
            
     
              </td>
            </tr>
          </table>

          <p>
           <br/>
          </p>
         
          <table className="GeneratedTable page2el" id="summaryTable2">
            
            <tr className='customTr'>
              <td colSpan={5} className="customTd customHeader">Past and Present Medical Problems</td>
            </tr>

            {
              info1.map((item, index) =>
                <React.Fragment key={genKey()}>
                  <tr  key={genKey()} className='customTr'>
                    <td colSpan={5} className="customTd">&nbsp; </td>
                  </tr>
                  {
                    index === 0 ?
                      <tr key={genKey()} className='customTr'>
                        <td style={{ "width": "300px" }} className="customTd customHeader">{item.category}</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">No</td>
                        <td colSpan={2} className="customTd"></td>
                      </tr>
                      : null
                  }
                  {
                    index === 1 ?
                      <tr key={genKey()} className='customTr'>
                        <th style={{ "width": "300px" }} className="customTd customHeader">{item.category}</th>
                        <th style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</th>
                        <th style={{ "width": "50px" }} className="center-align customTd customHeader">No</th>
                        <th className="customTd customHeader"> Remarks </th>
                        <th className="customTd customHeader"> Date </th>
                      </tr>
                      : null
                  }

                  {
                    index === 2 ?
                      <tr key={genKey()} className='customTr'>
                        <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                        <td className="customTd customHeader" colSpan={4}> {item.description || ''} </td>
                      </tr>
                      : null
                  }

                  {
                    index === 3 ?
                      <tr key={genKey()} className='customTr'>
                        <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                        <td className="customTd customHeader" colSpan={4}> {item.description || ''} </td>
                      </tr>
                      : null
                  }

                  {

                    item.child.map((item1, index1) =>
                      
                        <tr key={genKey()} className='customTr'>

                          {
                            item1.subcat.options.map(
                              (item2, index2) =>
                                <React.Fragment key={genKey()}>
                                  {
                                    index2 === 0 ?
                                      <td className="customTd" key={genKey()}><div>[{index1 + 1}] {item2.sub_category}</div></td> : null
                                  }

                                  {
                                    index2 === 0 && item2.type === 'text' && item2.span ?
                                      <td className="customTd" colSpan={item2.span} key={genKey()}><div>{formValues[item2.sub_category]}</div></td> : null
                                  }

                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length === 2 ?
                                      <React.Fragment key={genKey()}>
                                        <td className="center-align customTd" key={genKey()}><div>{formValues[item2.sub_category] === 'Yes' ? 'X' : ''}</div></td>
                                        <td className="center-align customTd" key={genKey()}><div>{formValues[item2.sub_category] === 'No' ? 'X' : ''}</div></td>
                                        {
                                          item2.span ? <td colSpan={item2.span} className="customTd"></td> : null
                                        }
                                      </React.Fragment> : null
                                  }
                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length > 2 ?
                                      <React.Fragment key={genKey()}>
                                        <td className="customTd" colSpan={4} key={genKey()}><div>{formValues[item2.sub_category]}</div></td>
                                      </React.Fragment> : null
                                  }
                                  {
                                    item2.type === 'text' && index2 > 0 ?
                                      <td className="customTd" colSpan={item2.span || 1} key={genKey()}><div>{formValues[item2.sub_category]}</div></td> : null
                                  }
                                  {
                                    item2.type === 'date' && index2 > 0 ?
                                      <td className="customTd" colSpan={item2.span || 1} key={genKey()}><div>{formValues[item2.sub_category]}</div></td> : null
                                  }
                                </React.Fragment>
                            )
                          }
                        </tr>

                     
                    )

                  }

                </React.Fragment>
              )
            }
 

          </table>

          <p>
            <br />
          </p>
        
          <table className="GeneratedTable page2el" style={{ marginTop: "10px" }} id="summaryTable3">

            <tr className='customTr'>
              <td className="customTd customHeader" colSpan={5}>Current Medications and Allergies</td>
            </tr>

            {
              info2.map((item, index) =>
                <React.Fragment key={genKey()}>
                  <tr  key={genKey()} className='customTr'>
                    <td className="customTd" colSpan={5}>&nbsp; </td>
                  </tr>
                  {
                    index === 0 ?

                      <tr key={genKey()}>
                        <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">No</td>
                        <td className="customTd customHeader" colSpan={2}></td>
                      </tr>

                      : null
                  }

                  {
                    index === 1 ?

                      <tr key={genKey()} className='customTr'>
                        <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</td>
                        <td style={{ "width": "50px" }} className="center-align customTd customHeader">No</td>
                        <td className="customTd customHeader" colSpan={2}>Dose / Frequency / Reason</td>
                      </tr>

                      : null
                  }

                  {

                    item.child.map((item1, index1) =>


                      <React.Fragment key={genKey()}>
                        <tr key={genKey()} className='customTr'>

                          {
                            item1.subcat.options.map(
                              (item2, index2) =>
                                <React.Fragment key={genKey()}>
                                  {
                                    index2 === 0 ?

                                      <td className="customTd" key={genKey()}>[{index1 + 1}] {item2.sub_category}</td>



                                      : null
                                  }

                                  {
                                    index2 === 0 && item2.type === 'text' && item2.span ?
                                      <td className="customTd" colSpan={item2.span} key={genKey()}>{formValues[item2.sub_category]}</td> : null
                                  }

                                  {
                                    index2 === 0 && item2.type === 'multitext' && item2.span ?
                                      
                                      <td className="customTd" colSpan={item2.span} key={genKey()}>{formValues[item2.sub_category]}</td>
                                      
                                      : null
                                  }

                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length === 2 ?
                                      <React.Fragment key={genKey()}>
                                        <td className="center-align customTd" key={genKey()}>{formValues[item2.sub_category] === 'Yes' ? 'X' : ''}</td>
                                        <td className="center-align customTd" key={genKey()}>{formValues[item2.sub_category] === 'No' ? 'X' : ''}</td>
                                        {
                                          item2.span ? <td className="customTd" colSpan={item2.span}></td> : null
                                        }
                                      </React.Fragment> : null
                                  }

                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length > 2 ?
                                      <td className="customTd" colSpan={4} key={genKey()}>{formValues[item2.sub_category]}</td>
                                      : null
                                  }

                                  {
                                    item2.type === 'text' && index2 > 0 ?
                                      <td className="customTd" colSpan={item2.span || 1} key={genKey()}>{formValues[item2.sub_category]}</td> : null
                                  }



                                </React.Fragment>
                            )
                          }
                        </tr>

                      </React.Fragment>
                    )

                  }

                </React.Fragment>
              )

            }


          </table>
          
          <p>
            <br />
          </p>
        
          <table className="GeneratedTable page2el" style={{ marginTop: "10px" }} id="summaryTable4">
            {
              props.signed === false ? 
              <tr className='customTr'>
                <td className="customTd customHeader" colSpan={5}>Review of Systems</td>
              </tr> : null
            }
            
             
           
              {shortValueReviewOfSystems && props.signed === false ? shortValueReviewOfSystems : null}
           
            {/* {
              info3.map((item, index) =>
                
                <React.Fragment key={genKey()}>

                

                  <tr key={genKey()} className='customTr'>
                    <td className="customTd" colSpan={5}>&nbsp; </td>
                  </tr>

                  <tr key={genKey()} className='customTr'>
                    <td className="customTd customHeader" style={{ "width": "300px" }}>{item.category}</td>
                    <td style={{ "width": "50px" }} className="center-align customTd customHeader">Yes</td>
                    <td style={{ "width": "50px" }} className="center-align customTd customHeader">No</td>
                    <td className="customTd customHeader" colSpan={2}>Remarks</td>
                  </tr>
                  
                
                  {

                    item.child.map((item1, index1) =>


                      <React.Fragment key={genKey()}>
                        <tr className='customTr'>

                          {
                            item1.subcat.options.map(
                              (item2, index2) =>
                                <React.Fragment key={genKey()}>
                                 
                                  {
                                    index2 === 0 ?

                                      <td className="customTd" key={genKey()}>[{index1 + 1}] {item2.sub_category}</td>
                                      : null
                                  }

                                  {
                                    item2.type === 'text' && index2 === 0 ?
                                      
                                      <React.Fragment key={genKey()}>
                                        
                                        <td className="customTd" colSpan={item2.span || 1}>{formValues[item2.sub_category]}</td> 
                                      </React.Fragment>
                                      
                                      : null
                                  }

                                  {
                                    index2 === 0 && item2.type === 'text' && item2.span ?
                                      <td className="customTd" colSpan={item2.span} key={genKey()}>{formValues[item2.sub_category]}</td> : null
                                  }

                                  {
                                    index2 === 0 && item2.type === 'multitext' && item2.span ?
                                      <td className="customTd" colSpan={item2.span} key={genKey()}>{formValues[item2.sub_category]}</td>
                                      : null
                                  }
                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length === 2 ?
                                      
                                      <React.Fragment key={genKey()}>
                                        <td className="center-align customTd">
                                          {
                                                                                                                     
                                            item.category === chatLogHistory.classification && item2.sub_category === chatLogHistory.main_symptom.replace(/_/g, ' ') ? 'X' : ''
                                          }  
                                        </td>

                                        <td className="center-align customTd">{formValues[item2.sub_category] === 'No' ? 'X' : ''}</td>
                                          {
                                            item2.span ? <td className="customTd" colSpan={item2.span}></td> : null
                                          }
                                      </React.Fragment> : null
                                  }

                                  {
                                    item2.type === 'checkbox' && item2.sub_options.length > 2 ?
                                      <td className="customTd" colSpan={4} key={genKey()}>{formValues[item2.sub_category]}</td>
                                      : null
                                  } 

                                  


                                </React.Fragment>
                            )
                          }
                        </tr>

                      </React.Fragment>
                    )

                  }

                </React.Fragment>
              )

            } */}

            <tr></tr>
          </table>
          
          <p>
            <br/>
          </p>

          <div className="signatureFooter">
          
          {
            medicalRecord.id && medicalRecord.signed === 'signed' ?
              <div>
                <h6>Version: {medicalRecord.revision}</h6>
                <span className="signature">{`${titleCase(clientPersonalInfo.first_name)} ${titleCase(clientPersonalInfo.last_name)}`}</span>
                <div>Signature</div>
                <span>Date Signed:<br/> { new Date(medicalRecord.date_signed).toString() || ''}</span>
              </div>

              : null
          }
          </div>

          <p>
            <br/>
          </p>

        </div>
      </div>

      <div className="flex-container2">
        {
          props.isDirty === true ?
          <div>
            <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { saveMedicalRecord(e); }}>Save</a>
          </div>
          :
          <div>
            <a href="#!" className="smallbutton gray medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }}>Save</a>
          </div>
        }
        {
          medicalRecord.id && medicalRecord.signed !== 'signed' && props.isDirty === false ? 
            <div>
              <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { signMedicalRecord(e); }}>Sign</a>
            </div>
            :
            <div>
              <a href="#!" className="smallbutton gray medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { }}>Sign</a>
            </div>
        }

        {
          medicalRecord.id && medicalRecord.signed === 'signed'  && props.isDirty === false?
            <div>
              <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { shareMedicalRecord(e); }}>Share</a>
            </div>
            :
            <div>
              <a href="#!" className="smallbutton gray medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { }}>Share</a>
            </div>
        }
       
        {/* <div>
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { downloadFile(e); }}>View</a>
        </div> */}
       

      </div>
      {/* <div style={{margin:"20px", padding:"20px"}}>
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px" }} onClick={(e) => { generatePDF(e); }}>Test</a>

          <button onClick={()=>generatePDF()}>PDF</button>
          <button onClick={()=>Convert_HTML_To_PDF()}>PDF</button>
      </div> */}


      


      <div id="div_signature" style={{width:"560px", display:"none"}}>
            <table id="tab_signature" style={{margin:"35px 70px;", width:"550px"}}>
                <tbody>
                  <tr>
                    <td>Signed:</td>
                  </tr>
                  <tr>
                    <td className="signature">{`${titleCase(clientPersonalInfo.first_name)} ${titleCase(clientPersonalInfo.last_name)}`}</td>
                  </tr>
                  <tr>
                    <td>Date Signed:<br/> { new Date(medicalRecord.date_signed).toString() || ''}</td>
                  </tr>
                  <tr>
                    <td>
                      {
                        medicalRecord.id && medicalRecord.signed === 'signed' ?
                        <h6>Version: {medicalRecord.revision}</h6> : <div></div>
                      }
                    </td>
                  </tr>
                </tbody>
            </table>
      </div>

      <Modal
        actions={[
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px","marginTop":"30px" }} onClick={(e) => {shareToProviders(e)}}>Share</a>,
          <a href="#!" className="smallbutton green medicalAnchor" style={{ "minWidth": "60px", "maxWidth": "60px","marginTop":"30px" }} onClick={(e) => {setOpenModal(false)}}>Close</a>
        ]}
        className ={"custom"}

        bottomSheet={false}
        fixedFooter={true}

        id="Modal-PDFviewer"
        open={openModal}
        options={{
          dismissible: false,
          endingTop: '10%',
          inDuration: 250,
          onCloseEnd: null,
          onCloseStart: null,
          onOpenEnd: null,
          onOpenStart: null,
          opacity: 0.5,
          outDuration: 250,
          preventScrolling: true,
          startingTop: '4%'
        }}
        
        
      > 
        <h6>List of your providers</h6>
        <div className="collection">
        {
          physiciansList && physiciansList.map((item)=>
            <React.Fragment key={genKey()}>
              { 
                item.full_name.length > 0  ? 
                  <a key={genKey()} href="#!" className="collection-item medicalAnchor">
                    <label>
                      <input type="checkbox" checked={item.shared === true} onChange={(e)=>toggleCheckBoxChange(e, item)}/>
                      <span className='blue-text'>{item.full_name}</span>
                    </label>
                  </a> 
                : null 
              }
            </React.Fragment>
          )
        }
        </div>

        <h6>Other providers requested for your signed medical records</h6>
        <div className="collection">
        {
          medicalRequests && medicalRequests.map((item)=>
            <React.Fragment key={genKey()}>
              { 
                item.provider_name.length > 0  ? 
                  <a key={genKey()} href="#!" className="collection-item medicalAnchor">
                    <label>
                      <input type="checkbox" checked={item.granted === "true"} onChange={(e)=>toggleCheckBoxChangeOtherProviders(e, item)}/>
                      <span className='blue-text'>{item.provider_name}</span>
                    </label>
                  </a> 
                : null 
              }
            </React.Fragment>
          )
        }


        </div>
      </Modal>
    
    </div>
  )
}

export default MedicalRecordsSummary
