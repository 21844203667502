import React, {useState, useEffect, useRef, useContext} from "react";
import hygeia from '../../assets/imgs/hygeia_pinay_with_mask_small.png';

import hygeiaHead from '../../assets/imgs/logo_64.png';
import { Button, Dropdown } from 'react-materialize';
import { Interactions } from 'aws-amplify';
import Amplify from 'aws-amplify';

import { I18n } from 'aws-amplify';
import i18 from '../../utils/i18n.json';
import { useHistory } from "react-router-dom";
import AppContext from '../../AppContext'
//import { isAndroid } from 'react-device-detect';

import './style.css'
//import Cryptr from 'cryptr';
import { checkDate, getItem, storeItem } from '../../utils/initialstate';


const avatar = () =>{
  return <img src={hygeia} alt="Hygeia"/>
}



const ChatBotUI1 = (props) => {
  
    const history = useHistory();
    const [responses, setResponses] = useState({web:[],hygeia:[],slotToElicit:''});
    const [userInput, setUserInput] = useState([]);
    const [toggle, setToggle] = useState(false);
    //const [lang, setLang] = useState('');
    const [isEng, setIsEng] = useState(true);
    const [chatMessages, setChatMessages] = useState([])
    const [currentMessage, setCurrentMessage] = useState([])
    const [chatOptions, setChatOptions] = useState([])
    const [locktxt, setLocktxt] = useState('');
    
    const [elicit, setElicit] = useState();
    const [undoThis, setUndoThis] = useState('');
    const [txtHint, setTxtHint] = useState('');
    //const [showStripe, setShowStripe] = useState(false);
    //const [modal, setModal] = useState({ promptToLoginRegister: false });
 
    const anchorBottom = useRef(null);
    const newSession = useRef(true);
    
    const inputRef = useRef();
    const inputRefTypeSize = useRef(0);
    const inputTypeRef = useRef("text");

    //const spaceRef = useRef();
    //const spaceRefButtons = useRef();
    const elem = document.getElementById("TextInput-4");

    const context = useContext(AppContext);
    
    const [toggleErrorMessage, setToggleErrorMessage] = useState(false);
    const errorMessageRef = useRef('');

    const [dimensions, setDimensions] = React.useState({ 
      height: 500,
      width: 380
    })
    function debounce(fn, ms) {
      let timer
      
      return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
          timer = null
          fn.apply(this, arguments)
        }, ms)
      };
    }

    const debouncedHandleResize = debounce(function handleResize() {
      if(window.innerWidth <= 760){
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth
        })
      }else{
        setDimensions({
          height: 500,
          width: 380
        })
      }
      
    }, 100)
    useEffect(()=>{
      console.log(dimensions);
    },[dimensions])

    useEffect(() => {
      window.addEventListener('resize', debouncedHandleResize)
  
      return _ => {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    })

    useEffect(() => {
      debouncedHandleResize();
      console.log('Logged User', context.loggedUser);
    },[]);

    
    useEffect(() => {
      if(context.clientInfo){
        console.log('Logged User', context.clientInfo);
      }
    },[context.clientInfo]);
    
    

    const handleKeyPress = (event) => {
        console.log(event.target.value)
        if(event.key === 'Enter'){
          //setLang('');
          handleSend();
        }
    };

    

    const handleSend = () =>{
      let u_input = elem.value;
      
      if(elicit.toLowerCase().includes("date_of_birth") || elicit.toLowerCase().includes("fecha de nacimiento")){
      
        let ret_date = checkDate(elem.value);
        if(ret_date === "Invalid Date"){
          elem.value = ""
        }else if(ret_date === undefined){
          elem.value = ""
        }else{
          elem.value = ret_date
        }

      }
      
      if(elem.value !==""){
        setUserInput([{rsp:u_input.split(' ').join('_'), txt: elem.value}]); 
        //document.getElementById('cbBox').classList.remove('chat-box-2'); 
        //document.getElementById('cbBox').classList.add('chat-box');

      }else{
        
        let msg = "Invalid input. Please try again."
        msg = I18n.get(msg)
        
        setChatMessages([...chatMessages,{from:'client', message:u_input, slot: elicit},{from:'hygeia', message:msg}]);
        
      }

    }

    const scrollToBottom = () =>{
      anchorBottom.current.scrollIntoView({behavior: "smooth", block: "end", inline: "end"});
    }

    const undoAction = (p_action, p_key, p_event) =>{
      //let resp = responses.web;
      let a = document.getElementsByClassName(p_key);

      for (let i = a.length-1; i>0; i--){
        a[i].remove();
      }
      console.log("UNDO ACTION: ", p_action + undoThis)
      setUserInput([{rsp: p_action+p_key, txt: p_action+p_key}]);
      //setUserInput([{rsp: p_action+undoThis, txt: p_action+undoThis}]);
    }

    const hideUndo = () =>{
      let x1 = document.getElementsByClassName("undo-pen");
      for(let i = 0; i <x1.length; i++){
        x1[i].className='hide';
      }
    }

    const langEs = btnTxt => {
      //setLang(btnTxt);
    }
    const changeLanguage = async (langToggle) => {
      //setIsEng(!isEng);
      if(isEng === false){
        setIsEng(true);
        setUserInput([{rsp:'set language to english', txt:'set language to english'}]);
        I18n.setLanguage('en');

      }else{
        setIsEng(false);
        setUserInput([{rsp:'set language to spanish', txt: 'set language to spanish'}]);
        I18n.setLanguage('es');

      }
    }


    const clickButton = (p_title_selected, p_value_selected, p_key) => {
      setChatOptions([]);
      setUserInput([{rsp: p_value_selected, txt: p_title_selected}]);
    }

    const removeSetResp = async (restart = '') => {
      try{

        setUserInput([{rsp:'stop', txt: 'stop'}]);
        //let a = document.getElementById("dvResponses").children;
    
        setResponses({...responses, web:[]})
        setChatMessages([]);
        setChatOptions([]);
      
        if(restart === 'startOver'){
          setTimeout(() => {
            setUserInput([{rsp:'new chat', txt: 'new chat'}]);
            
            newSession.current = false;
          }, 2000);
         
        }

      } 
      catch(err){
        console.log(err)
      }
    }

    const preFillInfo = async () =>{
      if(context.userState.first_name.length > 0 && context.userState.gender.length > 0 && context.userState.date_of_birth.length > 0){
        const response = await Interactions.send("Hygeia","captured client information");
      }
    }
    
    async function sendMessageToHygeia(){
      setToggleErrorMessage(false);
      errorMessageRef.current = "";

      if(userInput[0].txt.toLowerCase() === 'continue as a provider'){
        if(props.userData.authenticated === 'yes'){
          history.push({
            pathname: '/provider'
         })
        
          return
        }
        if(props.userData.authenticated === '' || props.userData.authenticated === undefined){
          history.push({
            pathname: '/redirect'
          })
          return
        }
        return
      }
      

      //spaceRef.current.style.display = "none";

      //let resp;
      focusOut();
      //resp = responses.web;
      
      //let d = new Date();
      //let k1 = d.getTime();
      
      hideUndo();
      setToggle(true);
      setUndoThis(elicit || '');

      console.log("USER INPUT", userInput[0].rsp);
      
      const response = await Interactions.send("Hygeia",userInput[0].rsp);
      let msg = "";
      console.log("session attributes", response.sessionAttributes);
      setToggle(false);

      msg = response.message;
      
      if(response.dialogState === "Fulfilled" && response.intentName === "connect_with_provider")
      {
        //const cryptr = new Cryptr(process.env.REACT_APP_KEY || 'T3stKey');
        let d = new Date();
        let t = d.getTime() / 60000;
        let login = false;
        let registration = false;

        setLocktxt('disabled');
        setTxtHint('Select your choice');
        
        if((props.userData.clientId === undefined || props.userData.clientId === '') && props.userData.authenticated === 'yes'){
          login = true;
        }

        if(props.userData.clientId !== '' && props.userData.authenticated === 'yes'){
          registration = true;
        }
        
        let tmpValue = {
          login,
          registration,
          gathering: true,
          authorization: false,
          queue: false,
          consult: false,
          payment: false,
          user_role: 'client',
          time: t,
          
        }
        //let userSessionAttributes = cryptr.encrypt(JSON.stringify(response.sessionAttributes));
        let userSessionAttributes = JSON.stringify(response.sessionAttributes);

        sessionStorage.setItem("chatLogs", JSON.stringify(tmpValue))
        sessionStorage.setItem("chatLogsHistory", userSessionAttributes)
        localStorage.setItem("chatLogHistory", userSessionAttributes);

        storeItem(context.clientInfo.id, response.sessionAttributes);
        storeItem(context.clientInfo.id + 'steps', tmpValue) ;
        storeItem(context.clientInfo.id + 'session', '0');
        
        history.push({
           pathname: '/redirect'
        })
         
        return
      }
      console.log("Chat Messages", chatMessages)
      setChatMessages([...chatMessages,{from:'client', message:userInput[0].txt, slot: elicit},{from:'hygeia', message:msg}]);
      setCurrentMessage(msg.split("#"));

      setElicit(response.slotToElicit );
      const mybtns = [];
      if(response.responseCard){
        setLocktxt('disabled');
        setTxtHint('Select your choice');
        //spaceRefButtons.current.display = 'block';
        if(response.responseCard.genericAttachments){
          response.responseCard.genericAttachments.forEach((element, index, array) => {
             for(let i=0; i<element.buttons.length; i++){
                mybtns.push(element.buttons[i]);
             }
          });
        }
        inputTypeRef.current = "text"
      }else{
       // spaceRefButtons.current.display = 'none';
      
        if(msg.toLowerCase().includes("date of birth")){
          setTxtHint('MM/DD/YYYY');
          inputTypeRef.current = "date";
        }else if(msg.toLowerCase().includes("fecha de nacimiento")){
          setTxtHint('MM/DD/YYYY');
          inputTypeRef.current = "date";
        }else{
          setTxtHint('Type your answer here');
          inputTypeRef.current = "text";
        }
        setLocktxt('');
        
        inputRef.current.focus();
      }
        //mybtns.sort((a, b) => a.text.length - b.text.length);
    
      setChatOptions(mybtns);
        
      setUserInput([]);
      elem.value = "";
      
      
    }

    //LATER: MOVE I18n Initialization to AppRoutes.js
    const setupVoc = () =>{
      
      I18n.putVocabularies(i18);
    }

    useEffect(()=>{
      setupVoc();
      I18n.setLanguage('en');
      //spaceRef.current.style.display = "none";      
    },[])


    useEffect(()=>{
     
    },[])

    useEffect(() => {
      scrollToBottom();
      console.log("TRIGGERED CHANGES IN CHAT MESSAGES")
    

    },[chatMessages])


   

    useEffect(() => {
      
      if(newSession.current && props.showChatBot){
        setUserInput([{rsp:'new chat', txt: 'new chat'}]);
        newSession.current = false;
      }
 
      scrollToBottom();
      if(userInput.length>0) sendMessageToHygeia()

      return () =>{
        console.log('clean')
      }

    }, [userInput, props.showChatBot]);
    //TEST us-east-1:dfdafcde-2a92-4b61-a2ff-a6cd4ac57432
    //DEV us-east-1:032715d6-d095-4dec-8f28-44d8b952f2a8
    Amplify.configure({
      Auth: {
        identityPoolId: 'us-east-1:032715d6-d095-4dec-8f28-44d8b952f2a8',
        region: 'us-east-1'
      },
      Interactions: {
        bots: {
          "Hygeia": {
            "name": "Hygeia",
            "alias": "$LATEST",
            "region": "us-east-1",
          }
        }
      }
    });

    const closeBot = async () =>{
        setToggle(true);
        let response = await Interactions.send("Hygeia","set language to english");
        
        setResponses({...responses, web:[]})
        setChatMessages([]);
        setChatOptions([]);

        setIsEng(true);
        setToggle(false);
        props.cbSetShowChatbot(); 
        removeSetResp(); 
        newSession.current=true;
    }

    const scrollDiv = () => {
      // spaceRef.current.style.display = "block";
      setTimeout(() => {
        scrollToBottom();
      }, 1000);
      //var elmnt = document.getElementById("divCheck");
      //elmnt.scrollIntoView();
    }

    const focusOut = () =>{
      //spaceRef.current.style.display = "none";
      
    }

    const handleKeyUpDate = (evt) =>{
      
     
      scrollDiv();
      evt = evt || window.event;
      const date = new Date();
      const yr = date.getFullYear();
      
      if(evt.keyCode !== 8 && inputTypeRef.current === "date"){
        let chrCode = evt.target.value.charAt(evt.target.selectionStart - 1).charCodeAt();
        if((chrCode >= 48 && chrCode <= 57) || (chrCode >= 96 && chrCode <= 105)) {
          evt = evt || window.event;
  
          var size = inputRef.current.value.length;
  
          if ((size === 2 && inputRef.current.value > 12)
            || (size === 5 && Number(inputRef.current.value.split('/')[1]) > 31) 
            || (size === 10 && Number(inputRef.current.value.split('/')[2]) > yr)) {
  
              inputRef.current.value = ''; 
              setToggleErrorMessage(true);
              let msg = "Invalid input. Please try again."
              msg = I18n.get(msg)
              errorMessageRef.current = msg;
              
              return;
               
          }
          if(size >= 10){
            let date = new Date(inputRef.current.value);  
            if(!date.getTime()){
              inputRef.current.value = ''; 
              setToggleErrorMessage(true);
              let msg = "Invalid input. Please try again."
              msg = I18n.get(msg)
              errorMessageRef.current = msg;
              
              return;
            }else{
              inputRef.current.value = checkDate(inputRef.current.value);
            }
          }

          if ((size === 2 && inputRef.current.value < 13)
            || (size === 5 && Number(inputRef.current.value.split('/')[1]) < 32)) {
  
            if(inputRefTypeSize.current < size && size > 0){
              inputRef.current.value += '/'; 
            }       
          } 
          
        }
      }
      inputRefTypeSize.current = size;
    }
   
    return (
      <div className="main" style={{height:dimensions.height, width: dimensions.width,display:(props.showChatBot) ? 'block' : 'none'}}>
        <section>
          <header>
              <div>
                    <span className="image-header"><img src={hygeiaHead} alt="Hygeia"/></span>
                    <span className="header-menu-container">
                      <span className="item1" onClick={()=>{changeLanguage()}}>
                          {isEng ? 'EN' : 'ES' }
                      </span>
                      <span className="item2">
                          <i className="menu-header-icons">language </i>
                      </span>
                      <span className="item3">
                          <i className="menu-header-icons" onClick={ ()=>{ closeBot() }}>highlight_off_filled</i>
                      </span>
                    </span>
                    
              </div>
              
          </header>
            <div className="body" style={{height:dimensions.height-120, width: dimensions.width-6}}>
                {
                  

                  chatMessages.map((item, i)=>{
                    
                    let x;
                    if(item.from === 'client'){
                      if(item.message !== 'new chat' && item.message !== 'stop'  && !item.message.includes('undo slot item'))
                      x = <div className='chat-msg-client' key={'msgClient' + i}> 
                              <div className='cm-msg-text-client-container'> 
                                <div className='undo-pen'>
                                  <i className="material-icons" onClick={ (e)=>undoAction('undo slot item ',item.slot,e)}> replay </i> 
                                </div>
                                <div className='cm-msg-text-client'>
                                  <span> {item.message} </span> 
                                </div>  
                              </div>
                          </div>

                    }else if(item.message !== 'Got it. Thank you.' && i <= chatMessages.length -2){
                      let items = item.message.split("#")
                      x = <div className='chat-msg-hygeia' key={'msgHygeia1' + i}> 
                            <span className='msg-avatar'> 
                              <img src={hygeia} alt="Hygeia"/> 
                            </span>
                            <div className='cm-msg-text-container'>
                              {
                                  items.map((item1, i1)=>{             
                                    return <div className='cm-msg-text' key={`msg${item1}${i1}`}> 
                                              <span> {item1} </span>     
                                           </div>        
                                  })
                              }
                              {i === items.length-1 ? <div className='hygeia-label'>Hygeia</div>  : ''}   
                            </div>
                          </div>
                    }
                    return x
                  })

                }
                
                <div className='chat-msg-hygeia'  style={ {display: (!toggle) ? 'block' : 'none' } }> 
                  <span className='msg-avatar'> {avatar()} </span>
                  <div className='cm-msg-text-container'>
                          {
                            currentMessage.map(function(item, i){
                              return <div className='cm-msg-text'> <span key = {'currentChat' + i}>{item}</span></div>
                            })
                          }
                    <div className='hygeia-label'>Hygeia</div> 
                  </div>
                </div>

                <div className='chat-msg-hygeia'  style={ {display: (toggle) ? 'block' : 'none' } }> 
                  <span className='msg-avatar'> {avatar()} </span>
                  <div className='cm-msg-text-container'>
                    <div className='cm-msg-text'> 
                      <div className="bubblingG">
                        <span id="bubblingG_1"></span>
                        <span id="bubblingG_2"></span>
                        <span id="bubblingG_3"></span>
                      </div>    
                    </div>
                    <div className='hygeia-label'>Hygeia</div> 
                  </div>
                </div>

                <div id='newOptions'> 
                  <div className="rowButtonOptions">
                  { 
                    chatOptions.map(function(item, i){
                      return <div className='columnButtonOptions' key={item.value + i} >
                                  <div onClick={ () => {clickButton(item.text ,item.value, i);}}>{ item.text }</div>
                              </div>
                    })
                  }
                  </div>      
                </div>
                
                
                <div className='chat-msg-hygeia'  style={ {display: (toggleErrorMessage) ? 'block' : 'none' } }> 
                  <span className='msg-avatar'> {avatar()} </span>
                  <div className='cm-msg-text-container'>
                      <div className='cm-msg-text'> 
                        <span>{errorMessageRef.current}</span>
                      </div>
                      <div className='hygeia-label'>Hygeia</div> 
                  </div>
                </div>

                <div ref = { anchorBottom } className="spacer" style={ {height: (toggleErrorMessage) ? '60px' : '10px' } } ></div>
                
            </div>
            
          
          <footer>
            
            <div className = "main-container">
              
              <div className="left-content">
                  <Dropdown
                        id="Dropdown_6"
                        options={{
                          alignment: 'left',
                          autoTrigger: true,
                          closeOnClick: true,
                          constrainWidth: true,
                          container: null,
                          coverTrigger: true,
                          hover: false,
                          inDuration: 150,
                          onCloseEnd: null,
                          onCloseStart: null,
                          onOpenEnd: null,
                          onOpenStart: null,
                          outDuration: 250
                        }}
                        trigger={<Button node="button" style={{backgroundColor:"#23b241"}}><i className="material-icons">reorder</i></Button>}
                      >
                        <a href="#! " onClick={()=>{removeSetResp('startOver');}} >Start Over</a>
                        <a href="#! " onClick={ ()=>{closeBot()}} >Close</a>
                  </Dropdown>
              </div>
              <div className="center-content">
                <input type="text" id="TextInput-4" 
                  ref={inputRef} onBlur = {()=>{ focusOut() }} 
                  onFocus={()=>{  scrollDiv() }}   
                  onKeyPress={(e)=>{ handleKeyPress(e) }} 
                  placeholder={txtHint} disabled = { locktxt } 
                  onChange = {(e)=>handleKeyUpDate(e)}
                />
              </div>
              <div className="right-content">
                <Button type="submit" style={{backgroundColor:"#23b241"}} id="chat-submit"><i className="material-icons" onClick = {() => { handleSend() }} >send</i></Button>
              </div>
              
            </div>
          </footer>
        </section>
      </div>
      
    );
} 
 

export default ChatBotUI1;